$blue: #1d56d6;
$indigo: #4e3795;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #e64d4d;
$orange: #f8cb00;
$yellow: #ffdb89;
$green: #45eb9f;
$teal: #20c997;
$cyan: #17a2b8;
$light-blue: #63c2de;
$dark-blue: #182e58;
$grey: #bfbfbf;
$color-white: #fff;
$color-black: #08080c;
$mirage: #1b2837;
$congress-blue: #034183;
$curious-blue: #169beb;
$bermuda-gray: #6f97b0;
$spring-wood: #f5f2eb;
$catskill-white: #ebf3f5;
$medium-turquoise: #56ccf2;
$salmon-orange: #ff8c4b;
$airline-dark: #1b2837;
$iris: #7879f1;
$iris100: #5d5fef;
$cream-can: #fdf5de;

//alerts
$failed: #f87d7e;
$warning: #f6cc5b;
$success: #56c67c;

$primary: $curious-blue;
$secondary: $yellow;
$tertiary: $dark-blue;

// be sure to check @import '~bootstrap/scss/variables'; to see what to override

:root {
  --dark-grey: #3b3b3b;
  --light-grey: #f5f5f5;
  --medium-grey: #a0a0a0;
  --medium-grey-2: #707070;
  --charcoal-black: #3b3b3b;
  --body-color: #ebf3f5;
}
