// Default Variables (Overrides Bootstrap)

// $font-family-base: Montserrat-Regular;
$font-size-base: 1rem; // 16px
$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 1.625; // 26px
$h3-font-size: $font-size-base * 1.25; // 20px
$h4-font-size: $font-size-base; // 16px
$h5-font-size: $font-size-base * 0.875;
$h6-font-size: $font-size-base;

h1,
h2,
h3,
h4 {
  font-family: Lato-Bold;
  color: $mirage;
}

h5 {
  font-family: Lato-Medium;
}

p {
  line-height: 28px;
  color: $mirage;
  font-size: 1rem; // 16px
}

.text-bold {
  font-family: 'Lato-Bold';
}

.text-medium {
  font-family: 'Lato-Medium';
}

.microcopy1 {
  font-size: 14px;
  line-height: 16.41px;
}

.microcopy2 {
  font-size: 12px;
  line-height: 14.06px;
}

// HEADER 3

.header3-regular {
  font-size: 36px;
  line-height: 42.19px;
}

.header3-medium {
  font-family: 'Lato-Medium';
  font-size: 36px;
  line-height: 42.19px;
}

.header3-bold {
  font-family: 'Lato-Bold';
  font-size: 36px;
  line-height: 42.19px;
}

// HEADER 5

.header5-regular {
  font-size: 24px;
  line-height: 28.13px;
}

.header5-medium {
  font-family: 'Lato-Medium';
  font-size: 24px;
  line-height: 28.13px;
}

.header5-bold {
  font-family: 'Lato-Bold';
  font-size: 24px;
  line-height: 28.13px;
}

// HEADER 6

.header6-regular {
  font-size: 20px;
  line-height: 25.78px;
}

.header6-medium {
  font-family: 'Lato-Medium';
  font-size: 20px;
  line-height: 25.78px;
}

.header6-bold {
  font-family: 'Lato-Bold';
  font-size: 20px;
  line-height: 25.78px;
}

// BODY 1

.body1-regular {
  font-size: 24px;
  line-height: 28.13px;
}

.body1-medium {
  font-family: 'Lato-Medium';
  font-size: 24px;
  line-height: 28.13px;
}

.body1-bold {
  font-family: 'Lato-Bold';
  font-size: 24px;
  line-height: 28.13px;
}

// BODY 2

.body2-regular {
  font-size: 22px;
  line-height: 25.78px;
}

.body2-medium {
  font-family: 'Lato-Medium';
  font-size: 22px;
  line-height: 25.78px;
}

.body2-bold {
  font-family: 'Lato-Bold';
  font-size: 22px;
  line-height: 25.78px;
}

// BODY 3

.body3-regular {
  font-size: 16px;
  line-height: 21px;
}

.body3-medium {
  font-family: 'Lato-Medium';
  font-size: 18px;
  line-height: 21px;
}

.body3-bold {
  font-family: 'Lato-Bold';
  font-size: 18px;
  line-height: 21px;
}

// BODY 4

.body4-regular {
  font-size: 14px;
  line-height: 18.75px;
}

.body4-medium {
  font-family: 'Lato-Medium';
  font-size: 16px;
  line-height: 18.75px;
}

.body4-bold {
  font-family: 'Lato-Bold';
  font-size: 16px;
  line-height: 18.75px;
}

// BODY 5

.body5-regular {
  font-size: 14px;
  line-height: 16.4px;
}

.body5-medium {
  font-family: 'Lato-Medium';
  font-size: 14px;
  line-height: 16.4px;
}

.body5-bold {
  font-family: 'Lato-Bold';
  font-size: 14px;
  line-height: 16.4px;
}

.text-strike {
  text-decoration: line-through;
}

.text-red {
  color: $failed;
}

.text-gray {
  color: $bermuda-gray;
}

.text-dark-blue {
  color: $congress-blue;
}

.text-blue {
  color: $curious-blue;
}

.text-white {
  color: $color-white;
}

.text-orange {
  color: $salmon-orange;
}
