@import '~@angular/material/theming';
@include mat-core();

/**
*  for the setting another custom color, just go here and generate http://mcg.mbitson.com/
*/

/* For use in src/lib/core/theming/_palette.scss */
$mat-primary: (
  50: #fceaea,
  100: #f8caca,
  200: #f3a6a6,
  300: #ee8282,
  400: #ea6868,
  500: #e64d4d,
  600: #e34646,
  700: #df3d3d,
  800: #db3434,
  900: #d52525,
  A100: #ffffff,
  A200: #ffdddd,
  A400: #ffaaaa,
  A700: #ff9090,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-secondary: (
  50: #fffbe0,
  100: #fff6b3,
  200: #fff080,
  300: #ffea4d,
  400: #ffe626,
  500: #ffe100,
  600: #ffdd00,
  700: #ffd900,
  800: #ffd500,
  900: #ffcd00,
  A100: #ffffff,
  A200: #fffcf2,
  A400: #fff1bf,
  A700: #ffeba6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-warn: (
  50: #fceaea,
  100: #f8caca,
  200: #f3a6a6,
  300: #ee8282,
  400: #ea6868,
  500: #e64d4d,
  600: #e34646,
  700: #df3d3d,
  800: #db3434,
  900: #d52525,
  A100: #ffffff,
  A200: #ffdddd,
  A400: #ffaaaa,
  A700: #ff9090,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$app-primary: mat-palette($mat-primary);
$app-accent: mat-palette($mat-secondary);
$app-warn: mat-palette($mat-warn);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($app-theme);

// Other default custom material style and theme that needs adjusting should be put here

// INPUT TEXT
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: var(--primary);
  height: 2px !important;
}

.mat-form-field-appearance-legacy.mat-form-field-invalid:not(.mat-focused) .mat-form-field-ripple {
  height: 2px;
}

.mat-form-field-underline {
  transform: none !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  margin-left: 10px;
}

input.mat-input-element {
  padding-left: 10px;
}

textarea.mat-input-element {
  padding: 2px 10px !important;
}

.mat-error {
  margin-left: 10px;
}

// END OF INPUT TEXT
.mat-button,
.mat-stroked-button,
.mat-flat-button {
  border-radius: 0 !important; // All of the Aerotrax components doesn't have border-radius

  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;

  .mat-button-wrapper,
  .mat-button-wrapper label {
    font-family: Lato-Bold;
    font-size: 1rem;
    padding: 10px;
  }
}

button,
a {
  &.mat-stroked-button {
    border: 2px solid var(--primary);
    border-color: var(--primary);

    &:not(.mat-button-disabled) {
      border-color: var(--primary);
    }
  }

  &.upload-btn {
    padding: 0;

    .mat-button-wrapper {
      padding: 15px;

      label {
        font-family: Lato-Bold;
        font-size: 1rem;
        padding: 0px 15px;
        margin: 0 -15px;
        display: inline-flex;
        align-items: center;
      }
    }
  }
}

mat-progress-bar {
  &.mat-progress-bar {
    height: 10px;
    border-radius: 10px;

    .mat-progress-bar-buffer {
      background-color: #e2e2e2;
    }

    .mat-progress-bar-fill::after {
      border-radius: 10px;
    }
  }
}

mat-slider {
  .mat-slider-track-fill,
  .mat-slider-thumb,
  .mat-slider-thumb-label {
    background-color: #169beb !important;
  }

  .mat-slider-thumb-label {
    right: -20px !important;
    top: -54px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .mat-slider-thumb-label-text {
    color: #fff;
  }

  .mat-slider-focus-ring {
    $color: #fff;
    background-color: #169beb !important;
  }
}
