// Inter Font

@font-face {
  font-family: Inter-Black;
  src: url(./inter/Inter-Black.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(./inter/Inter-Bold.ttf);
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url(./inter/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: Inter-ExtraLight;
  src: url(./inter/Inter-ExtraLight.ttf);
}

@font-face {
  font-family: Inter-Light;
  src: url(./inter/Inter-Light.ttf);
}

@font-face {
  font-family: Inter-Medium;
  src: url(./inter/Inter-Medium.ttf);
}

@font-face {
  font-family: Inter-Regular;
  src: url(./inter/Inter-Regular.ttf);
}

@font-face {
  font-family: Inter-SemiBold;
  src: url(./inter/Inter-SemiBold.ttf);
}

@font-face {
  font-family: Inter-Thin;
  src: url(./inter/Inter-Thin.ttf);
}

// Roboto Font

@font-face {
  font-family: Roboto-Black;
  src: url(./roboto/Roboto-Black.ttf);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(./roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: Roboto-Light;
  src: url(./roboto/Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(./roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(./roboto/Roboto-Regular.ttf);
}

// Lato Font

@font-face {
  font-family: Lato-Regular;
  src: url(./lato/Lato-Regular.ttf);
}

@font-face {
  font-family: Lato-Medium;
  src: url(./lato/Lato-Regular.ttf);
}

@font-face {
  font-family: Lato-Bold;
  src: url(./lato/Lato-Bold.ttf);
}
