@import './app/styles/abstracts/variables';
@import './app/styles/base/typography';
@import './app/styles/base/base';
@import './app/styles/themes/material-default';
@import './app/styles/themes/bootstrap';

.new-custom-container {
  max-width: 1800px;
}

.custom-container {
  // max-width: 1485px !important;
  max-width: 1400px;
}

ul {
  list-style: none;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

.disable-click {
  opacity: 0.5;
  p {
    cursor: not-allowed !important;
  }
}

.cursor-none {
  cursor: default !important;
}

.text-underline {
  text-decoration: underline;
}

.full-width {
  width: 100%;
}

.no-outline {
  &:hover,
  &:active,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

a {
  &.mute {
    color: var(--medium-grey-2);
  }

  &.underline {
    text-decoration: underline;
  }

  &.bold {
    font-family: Lato-Bold;
  }
}

.required:after {
  content: ' *';
  color: $failed;
}

.not-required:after {
  content: ' (optional)';
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.items-center {
  display: flex;
  align-items: center;
}

.text-center {
  text-align: center;
}

.items-end {
  display: flex;
  align-items: flex-end;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

button,
.btn {
  border-radius: 50px !important;
}

.button-container,
app-reusable-button,
label {
  &:focus {
    outline-width: 0px !important;
    outline: none !important;
  }
}

.user-info {
  li {
    a {
      &:focus {
        outline-width: 0px !important;
        outline: none !important;
      }
    }
    &:focus {
      outline-width: 0px !important;
      outline: none !important;
    }
  }
  &:focus {
    outline-width: 0px !important;
    outline: none !important;
  }
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  text-shadow: none;

  background-color: $curious-blue;
  border-radius: 50px;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
}

::ng-deep {
  .mat-radio-outer-circle {
    border-color: $catskill-white;
  }

  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: $catskill-white;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $curious-blue;
  }
}

.flights-accordion {
  .card {
    border: none !important;

    &-header {
      border-radius: 0 !important;
      padding: 0;
      background-color: $color-white !important;
      border-bottom: none;
    }

    &-body {
      padding: 0;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.popover {
  max-width: 600px !important;
  min-width: 600px !important;
}

.hidden {
  display: none;
}

@media screen and (max-width: 1440px) {
  .custom-container {
    max-width: 1095px;
  }
}

.modal {
  &-backdrop {
    top: 102px !important;
    z-index: 1;

    background-color: $color-white;

    opacity: 0 !important;
  }

  &-open {
    padding-right: 0 !important;
  }
}

.example-sidenav-container {
  &::ng-deep {
    .mat-drawer-shown {
      body & {
        overflow: hidden;
      }
    }
  }
}

.fit-content-width {
  width: fit-content !important;
}

.mat-dialog-container {
  border-radius: 16px !important;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: $color-white;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: $curious-blue;
}
