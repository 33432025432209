@import '~bootstrap/scss/bootstrap';

.dropdown-menu {
  overflow: hidden;
}

.form-control[readonly] {
  background-color: white;
  opacity: 1;
}

.form-group {
  label {
    color: black;
    font-weight: 500;
  }

  .form-control {
    &::placeholder {
      color: $bermuda-gray;
      font-weight: 400;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Some browsers will not display the caret when using calc, so we put the fallback first */
    background: url('../../../assets/icons/select-icon.png') white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
    background: url('../../../assets/icons/select-icon.png') white no-repeat calc(100% - 20px) !important; /* Better placement regardless of input width */

    /*For IE*/
    &::-ms-expand {
      display: none;
    }
  }
}

.btn {
  border-radius: 0;
}

.btn-link {
  &.aero {
    font-family: Lato-Bold;
    color: var(--primary);
    font-size: 1.25rem;
  }
}

.form-check-label {
  font-family: Lato-Bold;
  color: var(--dark-grey);
  margin-left: 0.875rem;
}

// start of custom switch style

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: transparent !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $catskill-white !important;
  background-color: $catskill-white !important;
}

.custom-control-input:checked ~ .custom-control-label::after {
  background-color: $success !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  background-color: $catskill-white !important;
  border-color: $catskill-white !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $catskill-white !important;
  background-color: $catskill-white !important;
}

.custom-control-input:not(:focus):not(:checked) ~ .custom-control-label::before {
  border-color: $catskill-white !important;
  background-color: $catskill-white !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::after {
  background-color: $bermuda-gray !important;
}

.custom-control-input:not(:focus):not(:checked) ~ .custom-control-label::after {
  background-color: $bermuda-gray !important;
}

// end of custom switch style

// Keep this below
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/functions';
