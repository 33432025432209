*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  border-radius: 0;
}

html,
body {
  height: 100vh;
  width: 100%;

  * {
    font-family: Lato-Regular;
  }
}

body {
  margin: 0;
  background: var(--body-color) !important;
}

hr {
  background-color: $bermuda-gray;
  opacity: 0.2;
  border-radius: 2px;
}

.indicator-inactive {
  padding: 5px;
  border-radius: 50%;
  background-color: $bermuda-gray;
}

.indicator-active {
  padding: 5px;
  border-radius: 50%;
  background-color: $curious-blue;
}

.min-height {
  min-height: calc(100vh - 408px);
}
